<template>
  <div>
    <div class="overlay-container" :style="`width:${screenWidth / 2}px;  height: ${screenHeight / 2 + 80}px`">
      <slot />

      <!-- The overlay has to be wrapped in a parent with the correct size, otherwise it will try to fill the parent height -->
      <div :style="`width:${screenWidth / 2}px; height: ${screenHeight / 2}px `">
        <draggable
          v-if="imageSrc"
          :w="mediaWidth / 2"
          :h="mediaHeight / 2"
          class="overlay__carousel"
          :x="mediaX / 2"
          :y="mediaY / 2"
          :parent="testParent"
          :max-height="screenHeight / 2"
          :active="active"
          class-name-active="overlay__carousel--active"
          class-name-resize="overlay__carousel--active"
          :max-width="screenWidth / 2"
          @resizing="onResize"
          @dragging="onDrag"
          @activated="setActiveLayer(true)"
          @deactivated="setActiveLayer(false)"
        >
          <span class="overlay__carousel-label" :style="labelStyle">Media</span>

          <div v-if="activeLayer" class="layer__tools">
            <div class="layer__tools-list">
              <Button cta="primary" :icon="true" @clicked="$emit('removeMedia')">
                <Tooltip position="tc" label="Remove" />
                <Icon icon="trashcan" width="14" />
              </Button>
            </div>
          </div>
          <div class="overlay__inner" :style="alignStyles">
            <div class="overlay__img">
              <img v-if="imageSrc.type === 'image' || imageSrc.type === undefined" :src="imageSrc.imageSrc" />

              <Video v-else-if="imageSrc.type === 'video'" :width="mediaWidth" :src="imageSrc.imageSrc" />
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vue-draggable-resizable";
import { Icon } from "cavea-design-system";

export default {
  name: "PreviewOverlay",

  components: {
    draggable,
    Icon,
    Video: () => import("@/components/overlay-tool/Video"),
    Tooltip: () => import("@/components/Tooltip"),
    Button: () => import("@/components/Button"),
  },

  props: {
    imageSrc: { type: Object, default: null },
    mediaWidth: { type: Number, default: 400 },
    mediaHeight: { type: Number, default: 400 },
    align: { type: Object, required: true },
    mediaX: { type: Number, default: 0 },
    mediaY: { type: Number, default: 0 },
    scale: { type: Number, default: 1 },
    active: { type: Boolean },
    screenWidth: { type: Number, default: 1920 },
    screenHeight: { type: Number, default: 1080 },
  },

  data() {
    return {
      activeLayer: false,
      testParent: true,
    };
  },

  computed: {
    labelStyle() {
      const scale = 100 / this.scale / 100;
      return `transform: scale(${scale}); transform-origin: center; translate3d( 0, 0, 0);`;
    },

    alignStyles() {
      return `align-items: ${this.align.v}; justify-content: ${this.align.h};`;
    },
  },

  watch: {
    screenWidth() {
      this.updateScreenSize();
    },

    screenHeight() {
      this.updateScreenSize();
    },
  },

  methods: {
    /**
     * @summary set active media layer
     */
    setActiveLayer(value) {
      this.activeLayer = value;
      this.$emit("activeLayer", value);
    },

    /**
     * @summary update overlay screen size
     */
    updateScreenSize() {
      window.setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },

    /**
     * @summary emit new overlay media position
     * @emits object
     */
    onDrag(x, y) {
      this.$emit("newPos", { x, y });
    },

    /**
     * @summary emit new overlay media size
     * @emits object
     */
    onResize(x, y, width, height) {
      this.$emit("onResize", { width, height });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_colors";
@import "@/styles/_globals";

.layer__tools {
  display: flex;
  position: absolute;
  background-color: color(white, base);
  border-radius: $border-radius;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 1000;
}

.layer__tools-list {
  display: flex;
}

.overlay-container {
  flex-shrink: 0;
  border: 1px solid color(border, base);
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: $box-shadow;
  overflow: hidden;
  background-color: color(white, base);
}

.overlay__carousel {
  cursor: move;
  border: 1px solid color(border, base);

  &:hover,
  &--active {
    border: 1px solid color(cta, primary);

    .overlay__carousel-label {
      color: color(cta, primary);
    }

    .handle {
      display: block !important;
    }
  }
}

.overlay__inner {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.overlay__img {
  display: flex;

  > img,
  video {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.overlay__carousel-label {
  position: absolute;
  left: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 0.675rem;
  top: -1.125rem;
}

.handle {
  width: 0.5rem;
  height: 0.5rem;
  z-index: 1000;
  cursor: e-resize;
  background-color: #4a75e2;
  position: absolute;

  &-tl {
    top: -0.25rem;
    left: -0.25rem;
    cursor: nwse-resize;
  }

  &-tr {
    top: -0.25rem;
    right: -0.25rem;
    cursor: nesw-resize;
  }

  &-tm {
    top: -0.25rem;
    left: calc(50% - 0.25rem);
    cursor: n-resize;
  }

  &-bm {
    bottom: -0.25rem;
    left: calc(50% - 0.25rem);
    cursor: n-resize;
  }

  &-br {
    bottom: -0.25rem;
    right: -0.25rem;
    cursor: nwse-resize;
  }

  &-bl {
    bottom: -0.25rem;
    left: -0.25rem;
    cursor: nesw-resize;
  }

  &-ml {
    left: -0.25rem;
    top: calc(50% - 0.25rem);
    cursor: e-resize;
  }

  &-mr {
    right: -0.25rem;
    top: calc(50% - 0.25rem);
    cursor: e-resize;
  }
}
</style>
